
import Vue, { PropType } from 'vue'
import taskListActions from '@/components/chisai/GCB2/taskList/actions'
import { getModa, saveCustomModa, setChangedModaNotification } from '../../../../api/chisai/GCB2'
import { Connector, Project } from '../../../../types/main'
import { CONNECTOR_TYPES } from '../../../../vars/general'
import {
    InnerMessageDHWithActivationId,
    massMailmessage,
    ModifiedMessageDH,
    sendMassMail,
} from '../../../../api/mailing'
import MessageTextEditor from '../../../inputs/MessageTextEditor/MessageTextEditor.vue'
import { IGCB2Connector, ManualCommunication } from '../../../../types/GCB2'
import messsages from '@dataheroes/messages'
import * as t from '@/store/chisai/GCB2/taskList/actionTypes'
import _ from 'lodash'
import { COMMUNICATION_ERROR_CODES, TASKLIST_COMMUNICATIONS_TYPES } from '../../../../vars/GCB2'
import checkConnectors from '../../../../helpers/functions/checkConnectors'
import TelegramIcon from '@/assets/vuetifyIcons/telegram.vue'
import WhatsappIcon from '@/assets/vuetifyIcons/whatsapp.vue'
import CloseIcon from '@/assets/vuetifyIcons/close.vue'
import SpinArrowsIcon from '@/assets/vuetifyIcons/spinArrows.vue'
import ResetArrowIcon from '@/assets/vuetifyIcons/resetArrow.vue'
import ChatPreviewWindow from './ChatPreviewWindow.vue'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import api from '@root/src/api/api'
import SpinnerLoader from '@root/src/components/SpinnerLoader.vue'
export default Vue.extend({
    components: {
        MessageTextEditor,
        TelegramIcon,
        WhatsappIcon,
        ResetArrowIcon,
        ChatPreviewWindow,
        SpinnerLoader,
        CloseIcon,
        SpinArrowsIcon,
    },
    props: {
        value: { type: Boolean as PropType<boolean> },
        item: { type: Object as PropType<ManualCommunication> },
        type: { type: String as PropType<string> },
        selectedCommunicationsLength: {type: Number, default: 0},
        currentItemPlace: {type: Number, default: 0}
    },
    data: () => ({
        loading: false as boolean,
        key: 0 as number,
        fileLoading: false as boolean,
        textMessage: '',
        textMessageBeforeChange: '',
        mediaBeforeChange: null,
        handlePreviewMode: 'Whatsapp' as 'Whatsapp' | 'Telegram',
        previewChangeMode: 'auto' as 'auto' | 'handle',
        allowedMimeTypes: ['image/png', 'image/jpeg', 'application/pdf', 'video/mp4'],
        maxFileSize: 20 * 1024 * 1024,
    }),
    computed: {
        showImagePreview() {
            const imageExtensions = ['.png', '.jpg', '.gif', '.jpeg']
            return (
                this.item?.activationMedia &&
                imageExtensions.some(ext => this.item?.activationMedia?.src?.endsWith(ext))
            )
        },
        showVideoPreview() {
            const videoExtensions = ['.mp4']
            return (
                this.item?.activationMedia &&
                videoExtensions.some(ext => this.item?.activationMedia?.src?.endsWith(ext))
            )
        },
        showDocPreview() {
            const docExtensions = ['.pdf']
            return (
                this.item?.activationMedia &&
                docExtensions.some(ext => this.item?.activationMedia?.src?.endsWith(ext))
            )
        },
        textMessageTrim() {
            return this.textMessage.trim()
        },
        isDialog: {
            get(): boolean {
                return this.value
            },
            set(value: boolean): void {
                this.$emit('input', value)
            },
        },
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        gcb2Connector(): IGCB2Connector {
            return this.project.connectors.find(
                (el: Connector) => el.connectorType === CONNECTOR_TYPES.GCB2
            ) as IGCB2Connector
        },
        currentPreviewMode(): 'Whatsapp' | 'Telegram' {
            if (this.previewChangeMode === 'handle') {
                return this.handlePreviewMode
            } else {
                if (this.item?.extra?.channel && this.item?.extra?.channel === 'wApi-telegram') {
                    return 'Telegram'
                } else {
                    return 'Whatsapp'
                }
            }
        },
        phoneNumber(): string {
            const phoneListType = this.item?.hrefInfo.filter(el => el?.phone)
            let number = null
            if (phoneListType) {
                number = phoneListType[0]?.phone
            }

            if (!number) return '+ 7 XXX XXX-XX-XX'

            const phoneNumber = parsePhoneNumberFromString(number)
            if (!phoneNumber) return number

            return phoneNumber.formatInternational()
        },
        disabledReset() {
            return (
                this.loading ||
                this.fileLoading ||
                (this.textMessage === this.textMessageBeforeChange &&
                    (this.item?.activationMedia?.src === this.mediaBeforeChange?.src || _.isEqual(this.item?.activationMedia, this.mediaBeforeChange)))
            )
        },
    },
    watch: {
        isDialog() {
            this.initData()
        },
        textMessage(v) {
            if (this.type === 'batch') {
                this.item.textMessage = v
            }            
        },
        currentItemPlace() {
            this.initData()
        }
    },
    methods: {
        initData() {
            this.textMessage = this.item.textMessage ?? ''
            this.textMessageBeforeChange = this.item.textMessage ?? ''
            this.mediaBeforeChange = this.item.activationMedia ?? null
        },
        async contactAction(communication: ManualCommunication) {
            this.loading = true
            let sendMessageResponse = { error: null, data: null } as any
            try {
                const messages = messsages.getMessages({
                    projectId: this.project.id,
                    connectors: checkConnectors(this.project.connectors),
                    project: this.project,
                    userId: this.$auth.user.sub,
                    communications: [Object.assign({}, communication, { textMessage: this.textMessage })],
                })

                const msgData = (_.first(messages)! as any) as ModifiedMessageDH

                msgData.messages[0].activationId = communication.activationId
                msgData.messages[0].media = this.item?.activationMedia?.src ? {
                    mimeType: this.item?.activationMedia?.mimeType,
                    src: this.item?.activationMedia?.src,
                } : null
                sendMessageResponse = await sendMassMail(msgData as any)
            } catch (err) {
                console.error(err)
                this.loading = false
                this.$store.dispatch('callNotify', 'Ошибка при отправке сообщения')
                this.isDialog = false
                return
            }
            this.loading = false
            const errors = sendMessageResponse.data.invalidMessages

            if (errors.length) {
                this.$store.commit(`GCB2/${t.APPEND_MESSAGE_ERRORS}`, errors)
            }

            if (sendMessageResponse.error || errors.length) {
                await taskListActions.communicationError({
                    vueInstanse: this,
                    item: communication,
                    textMessage: this.textMessage,
                    commType: TASKLIST_COMMUNICATIONS_TYPES.MESSAGE,
                    messageError: true,
                    messageErrorCode: COMMUNICATION_ERROR_CODES[errors[0].reason],
                })
                this.isDialog = false
                return
            } else {
                await taskListActions.inProgress({
                    vueInstanse: this,
                    item: communication,
                    textMessage: this.textMessage,
                    commType: TASKLIST_COMMUNICATIONS_TYPES.MESSAGE,
                })
                this.isDialog = false
            }
        },
        closeModal() {
            this.previewChangeMode = 'auto'
            if(this.type === 'solo' && !this.disabledReset) {
                this.resetChange()
            }
            this.isDialog = false
        },
        async changeTextMessageAction(item: any) {
            taskListActions.changeTextMessage(this, item, this.textMessage)
            this.isDialog = false
        },
        previewToggle(value: 'Whatsapp' | 'Telegram') {
            if (this.previewChangeMode !== 'handle') {
                this.previewChangeMode = 'handle'
            }
            this.handlePreviewMode = value
        },
        resetChange() {
            this.textMessage = this.textMessageBeforeChange
            this.item.activationMedia = _.cloneDeep(this.mediaBeforeChange)
            this.mediaBeforeChange = _.cloneDeep(this.item.activationMedia)
            this.key++
        },
        openUploadWindow() {
            const fileInput = this.$refs.fileInput as HTMLInputElement
            if (fileInput) {
                fileInput.click()
            }
        },
        onInputChange(e: Event) {
            const target = e.target as HTMLInputElement
            const files = target.files!
            this.uploadFile(files)
        },
        async uploadFile(files: File[] | FileList | null) {
            if (!files) return
            this.$emit('set-loading', true)
            this.fileLoading = true
            const file = _.first<File>(files)
            if (!this.allowedMimeTypes.includes(file?.type!)) {
                this.$root.$store.dispatch('callNotify', 'Не верный формат файла')
                this.fileLoading = false
                return
            }

            if (file && file?.size > this.maxFileSize) {
                this.$root.$store.dispatch('callNotify', 'Превышен максимальный размер файла')
                this.clearFile()
                this.$emit('set-loading', false)
                this.fileLoading = false
                return
            }
            const { data, error } = await api.file.uploadMedia({ projectId: this.project.id, file: file! })
            if (error) {
                this.$root.$store.dispatch('callNotify', 'Произошла ошибка при загрузке файла')
                this.$emit('set-loading', false)
                this.fileLoading = false
                return
            }
            this.update(data!.url || null)
            if (file?.type.startsWith('video/')) {
                await this.generateVideoThumbnail(file!)
            }
            this.$emit('set-loading', false)
            this.key++
            this.fileLoading = false
        },
        generateVideoThumbnail(file: File) {
            return new Promise(resolve => {
                const video = document.createElement('video')
                const canvas = document.createElement('canvas')
                const url = URL.createObjectURL(file)

                video.src = url
                video.addEventListener('loadeddata', async () => {
                    video.currentTime = 1 // Берем кадр на 1-й секунде
                })

                video.addEventListener('seeked', () => {
                    canvas.width = video.videoWidth
                    canvas.height = video.videoHeight
                    const ctx = canvas.getContext('2d')!
                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

                    canvas.toBlob(async blob => {
                        const thumbFile = new File([blob!], 'thumbnail.png', { type: 'image/png' })
                        const { data } = await api.file.uploadMedia({
                            projectId: this.project.id,
                            file: thumbFile,
                        })

                        this.update({
                            ...(this.item?.activationMedia || {}),
                            thumbnail: data!.url,
                        })

                        URL.revokeObjectURL(url)
                        resolve(true)
                    }, 'image/png')
                })
            })
        },
        update(v: any | null) {
            this.item.activationMedia = v
            this.key++
        },
        clearFile() {
            this.update(null)
            this.key++
        },
        setNext() {
            if (this.previewChangeMode !== 'auto') {
                this.previewChangeMode = 'auto'
            }
            this.$emit('set-next', this.item)
        },
        setPrew() {
            if (this.previewChangeMode !== 'auto') {
                this.previewChangeMode = 'auto'
            }
            this.$emit('set-prew', this.item)
        }
    },
})
